import { useMemo } from "react";
import UAParser from "./uaP";

export default function useDevice() {
  const os = useMemo(() => {
    const parser = new UAParser();
    const result = parser.getResult();
    return result.os.name.toLowerCase();
  }, []);
  return {
    os,
  };
}
