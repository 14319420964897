import { useMemo, useState, useEffect, useLayoutEffect } from "react";
import { Helmet } from "react-helmet";
import useDevice from "./hooks/useDevice";

function App() {
  const [link, setLink] = useState({});

  const { os: device } = useDevice();

  const [hashToken, setHashToken] = useState("");

  const fetchLinkData = async () => {
    const location = window.location;
    const hash = location.href?.replace("https://", "").replace("http://", "");

    const response = await fetch("https://haayaa.africa/api/link?url=" + hash);

    if (response.ok) {
      const data = (await response.json())?.data;
      setLink(data);
      setHashToken(data?.hash);
    }
  };

  useLayoutEffect(() => {
    if (window.location) {
      fetchLinkData();
    }
  }, [window.location.href]);

  return (
    <div className="">
      <div className="is-vertical-align header-wrapper">
        <a href="http://haayaa.africa" className="logo-wrapper">
          <img src="/logo.png" height="22" width="100" alt="haayaa" />
        </a>
        <a href="http://haayaa.africa/about" className="button primary">
          About
        </a>
      </div>
      <div className="is-center">
        <div className="col-12 col-6-md col-6-lg">
          <div className="card card-wrapper">
            <div className="col-12">
              <div className="">
                {link?.html && (
                  <div dangerouslySetInnerHTML={{ __html: link?.html }}></div>
                )}

                <div>
                  <div className="is-center row">
                    <div className="col-12 is-center pt-2">
                      <p className="text-white text-center">
                        <strong className="text-primary">Haayaa</strong> is the
                        best place to find shops, artisans and buy or sell
                        products and services with ease. <br />
                        <br /> Join Haayaa, in just a few clicks.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {device === "ios" && (
                <div className="is-center">
                  <a
                    className="button"
                    href="https://apps.apple.com/us/app/haayaa-sell-a-product/id1631151890"
                  >
                    Download
                    <img
                      src="https://icongr.am/devicon/apple-original.svg?size=10&color=currentColor"
                      alt="icon"
                    />
                  </a>
                  <a
                    href={`haayaa://link/${hashToken}`}
                    className="button primary"
                  >
                    Open Haayaa
                  </a>
                </div>
              )}
              {device === "android" && (
                <div className="is-center">
                  <a
                    className="button"
                    href="https://play.google.com/store/apps/details?id=africa.haayaa"
                  >
                    Download
                    <img
                      src="https://icongr.am/devicon/android-plain.svg?size=10&color=currentColor"
                      alt="icon"
                    />
                  </a>
                  <a
                    href={`haayaa://link/${hashToken}`}
                    className="button primary"
                  >
                    Open Haayaa
                  </a>
                </div>
              )}
              {device !== "android" && device !== "ios" && (
                <div className="row">
                  <div className="col-12">
                    <div className="is-center">
                      <img
                        src={
                          "https://chart.googleapis.com/chart?chs=400x400&cht=qr&chl=" +
                          URL +
                          "&choe=UTF-8"
                        }
                        alt=""
                        className="br-16"
                        width="220"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <p className="text-center text-white card-wrapper-ft-text">
                      Scan to download Haayaa App
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Helmet></Helmet>
    </div>
  );
}

export default App;
